<template>
    <CardComponent
        :title="title"
        :icon="icon"
        :tip="tip"
        link="/finance/accounting/daily/list"
        :filter="true"
        :options="options"
        width=".8rem"
        class="finance-card"
        @handleFilter="handleFilter"
    >
        <section v-loading="loading">
            <template v-if="hasData">
                <div class="gmv">
                    <p class="title">{{ data.name1 }}</p>
                    <p>
                        <span class="unit">￥</span>
                        <span id="numberJump"></span>
                    <!-- <span v-else class="price"></span> -->
                    </p>
                </div>
                <div class="other">
                    <div>
                        <p class="title">{{ data.name2 }}</p>
                        <span class="unit">￥</span>
                        <span class="price">{{ data.value2Format }}</span>
                    </div>
                    <div>
                        <p class="title">费率</p>
                        <span
                            :class="{
                                price: true,
                                red: data.rate2 < 0,
                            }"
                        >{{ data.rate2Format }}</span>
                    </div>
                </div>
                <div class="other">
                    <div>
                        <p class="title">{{ data.name3 }}</p>
                        <span class="unit">￥</span>
                        <span class="price">{{ data.value3Foramt }}</span>
                    </div>
                    <div>
                        <p class="title">费率</p>
                        <span :class="{ price: true, red: data.rate3 < 0 }">{{
                            data.rate3Format
                        }}</span>
                    </div>
                </div>
            </template>
            <Empty v-else />
        </section>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import accountSvc from 'services/accounting'
import { moneyFilter } from '@/utils/index'
import Empty from '@/components/empty'
export default {
    name: '',
    components: {
        CardComponent,
        Empty
    },
    props: {
        groupId: {
            type: Number,
            default: null
        },
        sign: {
            type: String,
            default: '运营经理'
        }
    },
    data () {
        return {
            title: '财务经营',
            tip: '财务经营',
            icon: 'iconfont icon-caiwuyunying',
            options: [
                {
                    label: '上周',
                    value: 1
                },
                {
                    label: '本周',
                    value: 0
                }
            ],
            currentUser: {},
            group: {},
            data: {},
            weekValue: 1,
            loading: false
        }
    },
    computed: {
        hasData () {
            return this.data && Object.keys(this.data).length > 0
        }
    },
    watch: {
        groupId (val) {
            if (val) {
                this.getData(this.weekValue)
            }
        }
    },
    mounted () {
        this.currentUser = this.$axios.getCurrentUser()
        this.getData(this.weekValue)
    },
    methods: {
        moneyFilter,
        handleFilter (val) {
            this.weekValue = val
            this.getData(val)
        },
        setJumpNumber (startNum, targetNum, time, selector) {
            this.$nextTick(() => {
                const that = this
                const dom = document.getElementById('numberJump')
                if (targetNum) {
                    // console.log(dom)
                    targetNum = Number(targetNum).toFixed(2)
                    let originNum = startNum
                    let stepNum = 0
                    let timeNum = time
                    dom.innerHTML = startNum

                    const timeId = setInterval(() => {
                        if (originNum < targetNum) {
                            timeNum -= 0.001
                            const strNum = originNum.toString()
                            // 数字比较少的时候直接用 + 1; 数字很大直接 +1 要很久才能调到最对应的数字，所有后三位数随机跳动的方式进行模拟生成
                            if (targetNum.toString().length < 6) {
                                stepNum += 1 // 这样才可以实现越跳越快的效果
                                originNum = originNum + stepNum
                                dom.innerHTML = originNum
                            } else {
                                stepNum += 1000 // 这样才可以实现越跳越快的效果
                                originNum = originNum + stepNum
                                dom.innerHTML =
                                    strNum.substr(0, strNum.length - 3) +
                                    Math.floor(Math.random() * 10) +
                                    Math.floor(Math.random() * 10) +
                                    Math.floor(Math.random() * 10)
                            }
                        } else {
                            dom.innerHTML = that.moneyFilter(targetNum, true)
                            clearInterval(timeId)
                        }
                    }, timeNum)
                } else {
                    dom.innerHTML = '0.00'
                }
            })
        },
        async getData (val) {
            try {
                this.data = {}
                let apiName =
                this.sign === '运营'
                    ? 'getDailyFinanceDataSurplus'
                    : 'getDailyFinanceData'
                // console.log(apiName, '=====apiName')
                this.loading = true
                const { data = {} } = await accountSvc[apiName]({
                    groupId: this.groupId,
                    num: val
                })
                this.loading = false
                this.data = data
                if (this.data) {
                    this.$nextTick(() => {
                        const dom = document.getElementById('numberJump')
                        // console.log(dom, '====cyw')
                        if (dom) {
                            dom.innerHTML = '0.00'
                        }
                    })
                    // 店铺边际贡献
                    this.data.value2Format = this.moneyFilter(this.data.value2, true)
                    // 店铺边际贡献 费率
                    this.data.rate2Format = this.data.rate2
                        ? Number(this.data.rate2).toFixed(2) + '%'
                        : '0.00%'
                    // 剩余盈余
                    this.data.value3Foramt = this.moneyFilter(this.data.value3, true)
                    // 剩余盈余 费率
                    this.data.rate3Format = this.data.rate3
                        ? Number(this.data.rate3).toFixed(2) + '%'
                        : '0.00%'
                    this.setJumpNumber(0, this.data.value1, 5, 'price')
                }
            } catch (error) {
                console.log(error)
                this.loading = false
            }
        }
    }
}
</script>
<style lang="less" scoped>
.finance-card {
    grid-row-end: span 2;
}

section {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 0.16rem;
    overflow: hidden;

    .title {
        height: 0.24rem;
        font-family: PingFangSC-Regular;
        font-size: 0.16rem;
        font-weight: 400;
        line-height: 0.24rem;
        color: #a7b6c3;
    }

    .gmv {
        height: 0.96rem;
        margin-bottom: 0.36rem;
        color: #54636f;

        .unit {
            font-size: 0.26rem;
            // font-size: 0.4rem;
        }

        #numberJump {
            height: 0.7rem;
            font-family: SanFranciscoDisplay-Medium;
            // font-size: 0.5rem;
            font-size: 0.44rem;
            font-weight: 500;
            line-height: 0.7rem;
        }
    }

    .other {
        display: flex;
        flex: 1;
        // justify-content: space-between;
        color: #54636f;

        .unit {
            // font-size: 0.25rem;
            font-size: 0.2rem;
        }

        .price {
            height: 0.44rem;
            font-family: SanFranciscoDisplay-Medium;
            font-size: 0.24rem;
            font-weight: 500;
            line-height: 0.44rem;
        }

        .price.green {
            font-size: 0.24rem;
            color: #39a36b;
        }

        .price.red {
            font-size: 0.24rem;
            color: #df5a68;
        }

        div:nth-child(1) {
            width: 50%;
        }

        div:nth-child(2) {
            flex: 1;
            padding-left: 25px;
        }
    }
}
</style>
